import type { Notification } from '../types'
import { useNotificationsStore } from '../stores/useNotificationsStore'

export function useNotification(notification: Ref<Notification>) {
  const { t } = useI18n()
  const { parseDate } = useDate()

  const time = computed(() => {
    const createdAt = parseDate(notification.value.createdAt)
    const now = parseDate().subtract(5, 'minute')
    const lessThenHour = parseDate().subtract(1, 'hour')
    const yesterday = parseDate().subtract(1, 'days')

    if (createdAt.isAfter(now, 'minute')) {
      return t('notifications.now')
    }
    if (createdAt.isAfter(lessThenHour, 'hour')) {
      return t('notifications.minutesAgo', {
        count: parseDate().subtract(createdAt.minute(), 'minute').minute(),
      })
    }
    if (createdAt.isSame(new Date(), 'day')) {
      return t('notifications.hoursAgo', {
        count: parseDate().subtract(createdAt.hour(), 'hour').hour(),
      })
    }
    if (createdAt.isSame(yesterday, 'day')) {
      return t('notifications.yesterday', [createdAt.format('hh:mm')])
    }
    return createdAt.format('DD.MM.YY, hh:mm')
  })

  const { readNotification: read } = useNotificationsStore()
  async function readNotification() {
    if (notification.value.read) return

    await read(notification.value.id)
  }

  return {
    time,
    readNotification,
  }
}
